.dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .options {
    width: max-content;
    position: absolute;
    top: 30px;
    right: 0;
    display: flex;
    flex-direction: column;
    background: white;
    row-gap: 8px;
    border-radius: 4px;
    padding: 12px;
    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;
      @include add-icon(url("../../assets/icons/logout.svg"), false, 20px, 20px);
    }
  }
}
