* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  color: black;
  font-weight: 400;
  font-size: 28px;
  @media (max-width: 576px) {
    font-size: 22px;
  }
}

h1,
h2,
h3,
h4 {
  color: $dd-primary;
  font-weight: 400;
  @include no-margin-padding;
  word-break: break-all;
}

body {
  background: $page-bg;
  @include no-margin-padding;
  font-family: "Graphik Web";
  // overflow-x: hidden;
  width: 100vw;
  position: relative;
  min-height: 100%;
}

textarea {
  font-family: "Graphik Web";
}

ul {
  list-style: none;
  @include no-margin-padding;
}

button {
  font-size: 1rem;
}

strong {
  font-weight: 500;
}

.container {
  @include container;
}

.dd-button-dark {
  @include dd-button;
  background-color: $dd-primary;
  color: $dd-light;
}

.dd-button-light {
  @include dd-button;
  background-color: $dd-light;
  color: $dd-text-dark;
}

.dd-button-rounded {
  @include dd-button-outline;
}

.dd-outline-input {
  @include dd-input-outline;
}

.dd-outline-textarea {
  @include dd-input-outline;
  height: 96px;
}

.error-text {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  @include add-icon(url("../../assets/icons/error.svg"), false, 20px, 20px);
}
.card-timestamp {
  font-weight: 400;
  font-size: $font-small;
  color: $dd-text-dark;
  width: 100%;
  text-align: end;
}
