.re-upload {
  strong {
    color: $dd-primary;
  }
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  .message-div {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .text-message {
      color: $dd-text-dark;
    }
  }
  .upload-button {
    @include dd-button-outline;
    white-space: nowrap;
    align-self: start;
    justify-self: end;
  }
}

.image-upload-div {
  max-width: 960px;
  height: 70vh;
  // height: 100%;
  overflow-y: auto;
  @include container;
  margin-inline: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-block: 46px;
  border-radius: 16px;
  gap: 58px;
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .image-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    div {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  .confirm-button {
    @include dd-button-outline;
    max-width: 364px;
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    h3 {
      color: white;
    }
    .loader-div {
      padding: 0;
    }
  }
}
