@mixin no-margin-padding {
  margin: 0;
  padding: 0;
}

@mixin flex-center-horizontal {
  display: flex;
  justify-content: center;
}

@mixin grid-width-limit-padding() {
  padding-inline: calc((100vw - 575px) / 2);
  @media (max-width: 800px) {
    $space: calc((100% - 1100px) / 2);
    padding-left: $space;
    padding-right: $space;
  }
}

@mixin add-icon($icon, $right: false, $height: 20px, $width: 20px) {
  @if $right {
    &:after {
      display: block;
      content: "";
      min-height: $height;
      min-width: $width;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: $icon;
    }
  } @else {
    &:before {
      display: block;
      content: "";
      min-height: $height;
      min-width: $width;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: $icon;
    }
  }
}

@mixin dd-input-outline {
  border: 1px solid $dd-muted;
  padding: 14px 16px;
  border-radius: 8px;
  outline: none;
  resize: none;
}

@mixin dd-button {
  max-width: 414px;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-weight: 400;
}

@mixin dd-button-outline {
  width: max-content;
  padding: 12px 24px;
  border-radius: 100px;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-weight: 400;
  background-color: $dd-primary;
  cursor: pointer;
  color: white;
  &:disabled {
    background-color: $dd-text-dark;
  }
}

@mixin options-list {
  .options-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    list-style-type: none;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    margin: 80px 0px;
    button {
      display: flex;
      column-gap: 10px;
      margin: 0;
      font-size: $font-medium !important;
      &:hover {
        background-color: #dfd8ea;
      }
      .tick {
        border: 1px solid $dd-button-border;
        border-radius: 100%;
        height: 20px;
        min-width: 20px;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        pointer-events: none;
        color: $dd-button-border;
      }
    }
    .selected-button {
      background-color: #ebf3f4;
    }
  }
}

@mixin container {
  width: 100%;
  padding-inline: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
}
