.profile-dropdown {
  display: flex;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;
  .name {
    color: white;
    display: flex;
    align-items: center;
    column-gap: 4px;
    @include add-icon(url("../../assets/icons/profile.svg"));
    word-break: break-all;
  }
  .link{
      border: none;
      background: transparent;
      text-transform: capitalize;
  }
}
