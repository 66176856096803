.change-password {
  padding-block: 72px;
  input {
    width: 362px;
  }
  label {
    margin-bottom: 8px;
    display: block;
  }
  .change-password-form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .new-password-div {
      display: flex;
      gap: 32px;
    }
  }
}
