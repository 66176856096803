.overlay-div {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 1, 1, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
