.doc-profile {
  display: flex;
  column-gap: 14px;
  align-items: center;
  .image {
    border-radius: 100%;
    background-color: $dd-text-dark;
    width: 57px;
    height: 57px;
    overflow: hidden;
  }
  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      color: black;
    }
    span {
      color: $dd-text-dark;
    }
  }
}
