// .loader-div {
//   width: 100%;
//   height: 100%;
//   padding: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .loader {
//     border: 6px solid $dd-muted;
//     border-top: 6px solid $dd-primary;
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;
//     animation: spin 2s linear infinite;
//   }

//   @keyframes spin {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
// }

.loader-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stroke-off-white {
  stroke: #75d0c5;
}
.stroke-secondary {
  stroke: #7f6d95;
}
.spinner-path {
  stroke-dashoffset: 0%;
  stroke-dasharray: 100%;
  animation: logoLoader 3s ease-in-out infinite;
  transform-origin: center;
}

@keyframes logoLoader {
  0% {
    stroke-dashoffset: 0%;
  }
  50% {
    stroke-dashoffset: 100%;
  }
  100% {
    stroke-dashoffset: 200%;
  }
}
