.consultations {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 64px;
  .text-error {
    color: red;
    display: flex;
    align-self: center;
  }
  .text-div-container {
    padding: 42px 20px;
    background: white;
    width: 100%;
    border-radius: 12px;
  }
  .error-div {
    display: flex;
    align-self: center;
    column-gap: 4px;
    color: $dd-primary;
    button {
      border: none;
      background: transparent;
      text-decoration: underline;
    }
  }
  .text-muted {
    color: $dd-text-dark;
    display: flex;
    text-align: center;
  }
  .consultation-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 33px;
    .add-button {
      @include add-icon(url("~/src/assets/icons/add.svg"));
    }
  }
  .consultation-block {
    margin-top: 31px;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    .consultations-list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }
  .prev {
    margin-top: 53px;
  }
}
