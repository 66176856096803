.dd-textarea {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.dd-input {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
