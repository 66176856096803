.ask-queries {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: white;
  padding: 20px;
  border-radius: 12px;
  .submit-button {
    @include dd-button-outline;
  }
}

.second-opinion-convo {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  position: relative;
  .line {
    position: absolute;
    left: 20px;
    top: 40px;
    height: calc(24px);
    align-self: center;
    border-right: 1px solid $dd-muted;
    z-index: 1;
  }
  .patient-convo {
    display: flex;
    @include add-icon(url("../../assets/icons/profile-container.svg"), false, 40px, 40px);
    align-items: center;
    column-gap: 16px;
    z-index: 2;
    .text{
      width: 80%;
    }
    .card-timestamp{
      width: 20%;
    }
  }
  .doctor-convo {
    display: flex;
    @include add-icon(url("../../assets/icons/doc-profile-container.svg"), false, 40px, 40px);
    align-items: flex-start;
    column-gap: 16px;
    z-index: 2;
  }
  .muted-convo {
    color: $dd-text-dark;
  }
  .case-report {
    background: transparent;
    width: 100%;
    padding: 0;
    .download-file {
      background: $dd-secondary;
    }
  }
}
