.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  .image-div {
    width: 180px;
    height: 106px;
    border-radius: 6px;
    overflow: hidden;
  }
  .upload-button {
    @include dd-button-outline;
    input[type="file"] {
      display: none;
    }
  }
}
