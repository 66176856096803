.header {
  padding-block: 18px;
  background: $dd-primary;
  justify-content: space-between;
  align-items: center;
  // overflow-x: hidden;
  .container {
    justify-content: space-between;
    .flex-div {
      display: flex;
      justify-content: space-between;
    }
  }
  .logo {
    height: 76px;
    width: 150px;
    background-size: contain;
    background-position: center;
  }
  .logo-se {
    object-fit: scale-down;
  }
  .logo-no {
    object-fit: cover;
  }
}
