.login {
  @include grid-width-limit-padding;
  background: $dd-primary;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  &__heading {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    margin-top: 80px;
  }
  &__subHeading {
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: rgba(255, 255, 255, 0.94);
  }
}
