.image-overlay {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 1, 1, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .image-container-double {
    margin: 8px;
    padding: 30px;
    padding-bottom: 40px;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    width: fit-content;
    height: fit-content;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 24px;
    .header-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cross-button {
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: url("../../assets/icons/cross-dark.svg");
        background-size: contain;
        background-position: center;
        margin: 8px;
      }
    }
    .images-div,
    .older-images-div {
      display: flex;
      gap: 14px;
      .image {
        font-size: 0;
        max-width: 60vh;
        max-height: 60vh;
        min-width: 130px;
        min-height: 130px;
        width: calc(100% / 2);
        border-radius: 6px;
        overflow: hidden;
        object-fit: cover;
       background-color: $dd-placeholder-background;
      }
    }
    .older-images-div {
      padding-top: 10px;
    }
  }
}
