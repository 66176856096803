/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2019 Schwartzco Inc.
        License: 1905-VEMJGC     
*/


@font-face {
  font-family: 'Graphik Web';
  src: url('Graphik-Medium-Web.woff2') format('woff2'),
       url('Graphik-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: 'Graphik Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Graphik Web';
  src: url('Graphik-Regular-Web.woff2') format('woff2'),
       url('Graphik-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}
