.collapse {
  .collapse-button {
    @include add-icon(url("../../assets/icons/arrow-down-black.svg"));
    border: none;
    background: transparent;
  }
  .collapse-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
}
