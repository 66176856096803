.uploaded-view {
  strong{
    color: $dd-primary;
  }
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  .images-div {
    display: flex;
    column-gap: 12px;
    .image-div {
      width: 122px;
      height: 84px;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}
