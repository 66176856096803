.consultation {
  display: grid;
  align-items: center;
  background: white;
  padding: 18px 16px;
  border-radius: 12px;
  gap: 36px;
  grid-template-areas: "img dnm dat cat vbt";
  @media (max-width: 576px) {
    width: 100%;
    grid-template-areas:
      "img"
      "dnm"
      "dat"
      "cat"
      "vbt";
  }
  .dr-name {
    grid-area: dnm;
    @media (max-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &::before {
        content: attr(data-title);
        color: black;
      }
    }
  }
  .date {
    grid-area: dat;
    @media (max-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &::before {
        content: attr(data-title);
        color: black;
      }
    }
  }
  .category {
    grid-area: cat;
    @media (max-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &::before {
        content: attr(data-title);
        color: black;
      }
    }
  }
  & > .view-button {
    display: flex;
    align-items: center;
    gap: 4px;
    @include add-icon(url("../../assets/icons/eye.svg"), false, 20px, 20px);
    grid-area: vbt;
    padding: 0;
  }
  span {
    color: $dd-text-dark;
  }
  .image-div {
    grid-area: img;
    height: 64px;
    width: 64px;
    @media (max-width: 576px) {
      width: 100%;
      height: 84px;
    }
    background: $dd-text-dark;
    border-radius: 6px;
    overflow: hidden;
    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
