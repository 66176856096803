.image-viewer {
  width: 100%;
  height: 100%;
  background-color: $dd-placeholder-background;
  position: relative;
  .thumbnail {
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    border-radius: 6px;
    overflow: hidden;
    .view-button {
      cursor: pointer;
      color: $dd-light;
      text-decoration: underline;
    }
  }
}

.image-overlay {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 1, 1, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .image-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    .image {
      max-width: 60vw;
      max-height: 80vh;
      border-radius: 6px;
      overflow: hidden;
    }
    .cross-button {
      cursor: pointer;
      position: absolute;
      top: -28px;
      right: -28px;
      width: 20px;
      height: 20px;
      background: url("../../assets/icons/cross.svg");
      background-size: contain;
      background-position: center;
      margin: 8px;
    }
  }
}
