$dd-primary: #4d4557;
$dd-secondary: #dfd8ea;
$dd-muted: rgba(0, 0, 0, 0.12);
$dd-light: white;
$dd-text-dark: rgba(0, 0, 0, 0.56);
$dd-button-border: #54939b;
$dd-placeholder-background: #f5f5f5;

$dd-input-border-active: #e5e5e5;
$dd-input-border-confirmed: rgba(135, 243, 189, 1);
$dd-input-border-error: rgba(223, 92, 68, 1);

$page-bg: #f7f7f7;

$small: 400px;
$medium: 800px;
$large: 1240px;

$font-small: 0.8rem;
$font-small-medium: 0.9rem;
$font-medium: 1rem;
$font-medium-large: 1.3rem;
$font-large: 2rem;
