.download-file {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 16px;
  border-radius: 8px;
  @include add-icon(url("../../assets/icons/download.svg"), true);
  column-gap: 16px;
  text-decoration: none;
  border: none;
  .file-name {
    color: $dd-primary;
    display: flex;
    @include add-icon(url("../../assets/icons/file.svg"));
    column-gap: 10px;
  }
}
