.summary {
  .summary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    .back-header {
      display: flex;
      gap: 12px;
      .back {
        border: none;
        background: transparent;
        @include add-icon(url("~/src/assets/icons/back.svg"));
        cursor: pointer;
        align-self: center;
      }
    }
    .feedback-button {
      @media (max-width: 576px) {
        display: none;
        width: fit-content;
      }
    }
    .redirect-feedback {
      @include dd-button-outline;
      @include add-icon(url("~/src/assets/icons/thumb-up.svg"));
      justify-self: flex-end;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .doc-header {
    display: flex;
    justify-content: space-between;
    .feedback-button {
      @media (min-width: 576px) {
        display: none;
      }
    }
    .redirect-feedback {
      @include dd-button-outline;
      @include add-icon(url("~/src/assets/icons/thumb-up.svg"));
      justify-self: flex-end;
      text-decoration: none;
      cursor: pointer;
      span {
        display: none;
      }
    }
  }
  padding-block: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 500px;
  .summary-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }
  .ask-query-div {
    margin-inline-start: 40px;
    .collapse {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  .collapse {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .summary-content {
    flex: 3;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .history-div {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
