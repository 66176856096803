@import url("assets/fonts/graphik/fonts.scss");
@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";

@import "base/reset";
@import "base/typography";

@import "components/header";
@import "components/consultation";
@import "components/doc-profile";
@import "components/case-report";
@import "components/vertical-line";
@import "components/case-details";
@import "components/image-viewer";
@import "components/image-viewer-double";
@import "components/dd-textarea";
@import "components/ask-queries";
@import "components/download-file";
@import "components/re-upload";
@import "components/uploaded-view";
@import "components/bank-id";
@import "components/image-upload";
@import "components/modal";
@import "components/profile-dropdown";
@import "components/dropdown";
@import "components/loader-div";
@import "components/collapse";
@import "components/quill";

@import "pages/consultations";
@import "pages/summary";
@import "pages/change-password";
@import "pages/login";
@import "pages/login-email";
