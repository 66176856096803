.bankid-login {
  @include options-list;
  padding-inline: 8px;
  iframe {
    width: 100%;
    min-height: 300px;
    border: none;
    margin: 80px 0px;
  }
  &__error {
    text-align: center;
    margin-top: 64px;
    color: #fff;
    font-size: 1.2rem;
  }
  &__tryagain {
    cursor: pointer;
  }
  &__return {
    color: $dd-primary;
    text-align: center;
    margin-top: 24px;
  }
}
.result__processing {
  margin-top: 40px;
  height: fit-content;
}

.signicat{
  .loader-div{
    margin-top: 25px;
  }
}