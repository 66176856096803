@mixin dd-input-focus {
  outline: none;
  box-shadow: 0 0 0 2px $dd-input-border-active;
}

@mixin dd-input-error {
  outline: solid $dd-input-border-error 2px;
}
.login-email {
  display: flex;
  flex-direction: column;
  background: $dd-primary;
  font-family: "Graphik Web";
  font-style: normal;
  .login-header * {
    color: white;
    text-align: center;
  }
  .login-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    h1 {
      font-size: 40px;
    }
    h3 {
      font-size: 25px;
    }
  }

  .login-email-container {
    min-height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    @include container;
    max-width: 500px;
    align-items: center;
    .PhoneInput {
      background: white;
      height: 56px;
      max-width: 434px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $dd-text-dark;
      padding: 14px 10px;
      &:focus {
        @include dd-input-focus();
      }
      &:focus-within {
        @include dd-input-focus();
      }
      input {
        font-size: 17px;
        color: $dd-primary;
        border: none;
        outline: none;
      }
    }
    .otp-container {
      max-width: 434px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .otp-input {
        height: 56px;
        width: 56px;
        border-radius: 8px;
        border: 1px solid $dd-text-dark;
        padding: 14px 10px;
        font-size: 17px;
        color: $dd-primary;
        outline: none;
        text-align: center;
        &:focus {
          @include dd-input-focus();
        }
        &:focus-within {
          @include dd-input-focus();
        }
      }
    }
    .input-div {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: 434px;
      width: 100%;
      margin-inline: auto;
      label {
        color: white;
        font-size: 15px;
        font-weight: 400;
      }
    }
    .password-input {
      background: white;
      height: 56px;
      max-width: 434px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $dd-text-dark;
      padding: 14px 10px;
      font-size: 17px;
      color: $dd-primary;
      outline: none;
      &:focus {
        @include dd-input-focus();
      }
      &:focus-within {
        @include dd-input-focus();
      }
    }
    .proceed-button {
      max-width: 434px;
      width: 100%;
      height: 56px;
      border-radius: 100px;
      background-color: $dd-secondary;
      border: none;
      font-size: 17px;
      font-weight: 400;
      .loader-div {
        padding: 0;
      }
      &:disabled {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    .error-div {
      width: 434px;
    }
  }
}
