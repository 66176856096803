.case-details {
  strong{
    color: $dd-primary;
  }
  display: flex;
  flex-direction: column;
  .row {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 16px;
  }
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  color: $dd-text-dark;
  row-gap: 20px;
  .image-div {
    width: 122px;
    height: 84px;
    border-radius: 5px;
    overflow: hidden;
  }
}
